import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';
import { getRemainingCredits } from '../Services';

type AvailableCreditsContextActions = {
  availableCredits: number;
  refreshCredits: () => void;
};

const AvailableCreditsContext = createContext(
  {} as AvailableCreditsContextActions
);

const AvailableCreditsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [availableCredits, setAvailableCredits] = useState<number>(0);

  const refreshCredits = () => {
    fetchCredits();
  };

  const fetchCredits = async () => {
    try {
      const response = await getRemainingCredits();
      setAvailableCredits(response.data.credits);
    } catch (error) {
      console.error('Failed to fetch credits:', error);
    }
  };

  return (
    <AvailableCreditsContext.Provider
      value={{ refreshCredits: refreshCredits, availableCredits }}
    >
      {children}
    </AvailableCreditsContext.Provider>
  );
};

const useAvailableCredits = (): AvailableCreditsContextActions => {
  const context = useContext(AvailableCreditsContext);

  if (!context) {
    throw new Error(
      'useAvailableCredits must be used within an AvailableCreditsProvider'
    );
  }

  return context;
};

export { AvailableCreditsProvider, useAvailableCredits };
