import { FC } from 'react';
import { Menu } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  AppstoreOutlined,
  CarOutlined,
  CreditCardOutlined,
  DashboardOutlined,
  FileDoneOutlined,
  SafetyCertificateOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useAdminAuthentication } from '../../../../Contexts';

type MenuItemType = {
  key: MenuItemKey;
  icon?: React.ReactNode;
  label: string;
  onClick?: () => void;
  children?: MenuItemType[];
};

enum MenuItemKey {
  Dashboard = 'dashboard',
  Customers = 'users',
  Cars = 'cars',
  Subscriptions = 'plans',
  Settings = 'settings',
  NewMenuPage = 'new-menu-page',
  PrivacyPolicy = 'privacy-policy',
  TermsCondition = 'terms-conditions',
  Page1 = 'karStudio Pages',
  Contactus = 'contact-us',
}

const SideBarMenu: FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isAuthenticated } = useAdminAuthentication();

  const onMenuItemClick = (itemKey: MenuItemKey): void => {
    const activeKey = getSelectedItemKey();

    if (activeKey === itemKey) {
      return;
    }

    let navigatePath: string | null = null;

    switch (itemKey) {
      case MenuItemKey.Dashboard:
        navigatePath = '/admin/dashboard';
        break;
      case MenuItemKey.Customers:
        navigatePath = '/admin/users';
        break;
      case MenuItemKey.Cars:
        navigatePath = '/admin/cars';
        break;
      case MenuItemKey.Subscriptions:
        navigatePath = '/admin/plans';
        break;

      case MenuItemKey.Settings:
        navigatePath = '/admin/settings';
        break;
      case MenuItemKey.Contactus:
        navigatePath = '/admin/Contacts';
        break;
      case MenuItemKey.NewMenuPage:
        navigatePath = '/admin/new-menu-page';
        break;
      case MenuItemKey.PrivacyPolicy:
        navigatePath = '/admin/privacy-policy';
        break;
      case MenuItemKey.TermsCondition:
        navigatePath = '/admin/terms-conditions';
        break;
      case MenuItemKey.Page1:
        navigatePath = '/admin/Seo-manager';
        break;
    }

    if (navigatePath) {
      navigate(navigatePath);
    }
  };

  const getMenuItems = (): MenuItemType[] => {
    if (!isAuthenticated) {
      return [];
    }

    const items: MenuItemType[] = [
      {
        key: MenuItemKey.Dashboard,
        icon: <DashboardOutlined />,
        label: 'Dashboard',
        onClick: () => onMenuItemClick(MenuItemKey.Dashboard),
      },
      {
        key: MenuItemKey.Customers,
        icon: <UserOutlined />,
        label: 'Customers',
        onClick: () => onMenuItemClick(MenuItemKey.Customers),
      },
      {
        key: MenuItemKey.Cars,
        icon: <CarOutlined />,
        label: 'Cars',
        onClick: () => onMenuItemClick(MenuItemKey.Cars),
      },
      {
        key: MenuItemKey.Subscriptions,
        icon: <CreditCardOutlined />,
        label: 'Subscriptions',
        onClick: () => onMenuItemClick(MenuItemKey.Subscriptions),
      },

      {
        key: MenuItemKey.Settings,
        icon: <SettingOutlined />,
        label: 'Settings',
        onClick: () => onMenuItemClick(MenuItemKey.Settings),
      },

      {
        key: MenuItemKey.Contactus,
        icon: <FileDoneOutlined />,
        label: 'Contact Us',
        onClick: () => onMenuItemClick(MenuItemKey.Contactus),
      },
      {
        key: MenuItemKey.NewMenuPage,
        icon: <AppstoreOutlined />,
        label: 'Pages',
        children: [
          {
            key: MenuItemKey.PrivacyPolicy,
            label: 'Privacy Policy',
            icon: <SafetyCertificateOutlined />,
            onClick: () => onMenuItemClick(MenuItemKey.PrivacyPolicy),
          },
          {
            key: MenuItemKey.TermsCondition,
            label: 'Terms & Condition',
            icon: <FileDoneOutlined />,
            onClick: () => onMenuItemClick(MenuItemKey.TermsCondition),
          },
          {
            key: MenuItemKey.Page1,
            label: 'KarStudio Pages',
            icon: <FileDoneOutlined />,
            onClick: () => onMenuItemClick(MenuItemKey.Page1),
          },
        ],
      },
    ];

    return items;
  };

  const getSelectedItemKey = (): string => {
    if (pathname === '/') {
      return MenuItemKey.Dashboard;
    }

    return Object.values(MenuItemKey).find((x) =>
      pathname.startsWith(`/${x}`)
    )!;
  };

  return (
    <Menu
      theme="dark"
      mode="inline"
      items={getMenuItems()}
      defaultSelectedKeys={[getSelectedItemKey()]}
    />
  );
};

export default SideBarMenu;
