import { FC, lazy, LazyExoticComponent, ReactElement } from 'react';

export type AppRoute = {
  key: string;
  path: string;
  isPrivate?: boolean;
  LazyComponent?: LazyExoticComponent<FC>;
  isLazyRoute?: boolean;
  Component?: ReactElement;
  skipIfAuthenticated?: boolean;
};

const CoreModule = lazy(() => import('./Modules/Core/CoreModule'));
const AdminModule = lazy(() => import('./Modules/Admin/AdminModule'));

export const appRoutes: AppRoute[] = [
  {
    key: 'admin',
    path: '/admin/*',
    isPrivate: false,
    isLazyRoute: true,
    skipIfAuthenticated: false,
    LazyComponent: AdminModule,
  },
  {
    key: 'public',
    path: '/*',
    isPrivate: false,
    isLazyRoute: true,
    skipIfAuthenticated: false,
    LazyComponent: CoreModule,
  },
];
