import axios from 'axios';
import { PageMetadata } from '../Types/meta';

const usersAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const getPageMetadata = async (url: string) => {
  try {
    const response = await usersAxios.get<PageMetadata>(
      '/page-metadata/metadata',
      {
        params: { url },
      }
    );

    return response.data;
  } catch (error) {
    return null;
  }
};
