import { FC } from 'react';
import { Button, Modal } from 'react-bootstrap';

interface Props {
  opened: boolean;
  onClose: (confirmed?: boolean) => void;
}

const FilterChangeConfirmation: FC<Props> = ({ opened, onClose }) => {
  return (
    <Modal show={opened} onHide={onClose} size="lg" centered id={'filterModal'}>
      <Modal.Header closeButton>
        <Modal.Title>Please Confirm</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>You are going to use your credit, want to proceed?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => onClose()}>
          CANCEL
        </Button>
        <Button className="custom-button" onClick={() => onClose(true)}>
          Proceed
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FilterChangeConfirmation;
