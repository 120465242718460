import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import { Tabs, TabsProps } from 'antd';
import { FC, memo, useCallback, useMemo, useState } from 'react';

import { EditorAccordionBaseProps } from '../../../Interfaces';
import { backgroundImages } from '../../../Constants';
import FilterChangeConfirmation from './FilterChangeConfirmation/FilterChangeConfirmation';
import {
  BackgroundImage,
  BackgroundImageCategory,
} from '../../../../../../Types';

enum BackgroundTabs {
  OUTSIDE = 'Outside',
  STUDIO = 'Studio',
  GARAGE = 'Garage',
}

interface Props extends EditorAccordionBaseProps {
  disabled: boolean;
  isCreatingNewProject: boolean;
  selectedBackground: BackgroundImage | null;
  onBackgroundChange: (background: BackgroundImage) => void;
}

const BackgroundSettings: FC<Props> = ({
  disabled,
  expanded,
  selectedBackground,
  isCreatingNewProject,
  onExpandChange,
  onBackgroundChange,
}) => {
  const [activeTab, setActiveTab] = useState<BackgroundTabs>(
    BackgroundTabs.OUTSIDE
  );
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [selectedBgForConfirmation, setSelectedBgForConfirmation] =
    useState<BackgroundImage | null>(null);

  const onFilterConfirmationClose = useCallback(
    (confirmed?: boolean) => {
      setShowConfirmation(false);

      if (confirmed) {
        onBackgroundChange(selectedBgForConfirmation!);
      }

      setSelectedBgForConfirmation(null);
    },
    [selectedBgForConfirmation, onBackgroundChange]
  );

  const onFilterClick = useCallback(
    (background: BackgroundImage) => {
      if (disabled) {
        return;
      }

      if (isCreatingNewProject) {
        setShowConfirmation(true);
        setSelectedBgForConfirmation(background);
      } else {
        onBackgroundChange(background);
      }
    },
    [disabled, isCreatingNewProject, onBackgroundChange]
  );

  const renderCategoryFilters = useCallback(
    (category: BackgroundImageCategory) => {
      return (
        <div className="background-images-box-card" style={{ maxHeight: '160px', overflowY: 'auto' }}>
          {backgroundImages
            .filter((x) => x.category === category)
            .map((x) => (
              <div
                key={x.url}
                className="background-images"
                onClick={() => onFilterClick(x)}
              >
                <img
                  src={x.url}
                  alt="car"
                  className={`img-fluid edit-car ${
                    selectedBackground?.url === x.url ? 'edit-car-active' : ''
                  }`}
                />
              </div>
            ))}
        </div>
      );
    },
    [selectedBackground, onFilterClick]
  );

  const tabItems = useMemo<TabsProps['items']>(() => {
    return [
      {
        key: BackgroundTabs.OUTSIDE,
        label: BackgroundTabs.OUTSIDE,
        children: renderCategoryFilters('outside'),
      },
      {
        key: BackgroundTabs.STUDIO,
        label: BackgroundTabs.STUDIO,
        children: renderCategoryFilters('studio'),
      },
      {
        key: BackgroundTabs.GARAGE,
        label: BackgroundTabs.GARAGE,
        children: renderCategoryFilters('garage'),
      },
    ];
  }, [renderCategoryFilters]);

  return (
    <>
      <FilterChangeConfirmation
        opened={showConfirmation}
        onClose={onFilterConfirmationClose}
      />

      <Accordion
        expanded={expanded}
        onChange={onExpandChange}
        className="editor-accordion"
      >
        <AccordionSummary>
          <Typography component="span">Choose a Background</Typography>
        </AccordionSummary>

        <AccordionDetails className="editor-nav-tabs">
          <Tabs
            centered
            items={tabItems}
            activeKey={activeTab}
            onChange={(key) => setActiveTab(key as BackgroundTabs)}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default memo(BackgroundSettings);
