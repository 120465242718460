import Logo from './Images/logo.svg';
import FacbookLogo from './Images/facebook.svg';
import GoogleLogo from './Images/google.svg';
import FooterLogo from './Images/footer-logo.svg';
import Loaction from './Images/location.svg';
import FooterFb from './Images/footerfb.svg';
import FooterInsta from './Images/footerinsta.svg';
import Carafter from './Images/carafter.png';
import CarBefore from './Images/carBefore.png';
import Upload from './Images/upload.svg';
import Error from './Images/error.svg';
import CarEhance from './Images/car-enhance.png';
import ErrorImage from './Images/erroeImage.png';
import RightImage from './Images/rightImage.png';
import Cemara from './Images/camera.svg';
import PhoneHand from './Images/phone-in-hand.svg';
import Car from './Images/car-image.svg';
import Editcar from './Images/edit-car.png';
import Share from './Images/Share.svg';
import Calender from './Images/Calender.svg';
import GreenTick from './Images/green-tick.svg';
import CarBorder from './Images/car-border.png';
import ReadyCar from './Images/ready-car.png';
import PhotoGraphy from './Images/photography-tips.png';
import TopView from './Images/top-view-car.png';
import ErrorIcon from './Images/erroeIcon.svg';
import ListIcon from './Images/list-icon.svg';
import PartnerShip from './Images/partnership.svg';
import Visa from './Images/visa.svg';
import MasterCard from './Images/master-card.svg';
import AmericanExpress from './Images/american-express.svg';
import Discover from './Images/discover.svg';
import Venom from './Images/venom.svg';
import Paypal from './Images/paypal.svg';
import userProfile from './Images/user-profile.png';
import Star from './Images/star.svg';
import AddIcon from './Images/add-icon.svg';
import newEditCar from './Images/NewEditedcar.png';
import newCar from './Images/Newcar.png';
import plate from './Images/plate.svg';
import hide from './Images/hide.svg';
import eye from './Images/eye.png';
import Sparkle from './Images/sparkle.png';
import SparklrCar from './Images/Sparkle-car.svg';
import SliderImage from './Images/slider-image.png';
import CarEnhanceTwo from './Images/car-enhance-2.png';
import CarEnhanceThree from './Images/car-enhance-3.png';
import PhotographyTip from './Images/tip.png';
import image88 from './Images/image 88.png';
import image103 from './Images/image 103.png';
import image104 from './Images/image 104.png';
import image107 from './Images/image 107.png';
import image108 from './Images/image 108.png';
import ForgotPassword from './Images/forgot-password.png';
import Key from './Images/Key.png';
import ArrowRight from './Images/Arrow-right.svg';
import ArrowRightWhite from './Images/Arrow-right copy.svg';
import MobileViewImage from './Images/mobileview-image.png';
import demoImg from './Images/demo-img.png';
import editedDemoImg from './Images/edited-demo.png';
import pencil from './Images/pencil.svg';
import afterImg from './Images/afterImg.png';
import beforeImg from './Images/beforeImg (1).png';
import CameraTry from './Images/try-camera.png';
import CarTry from './Images/Car-try.png';
import loader from './Loader/rotate 1.gif';
import WhiteCarIcon from './Images/white-car-icon.svg';
import imgOne from './Images/x1.png';
import imgTwo from './Images/x2.png';
import imgThree from './Images/ww1.png';
import imgFour from './Images/ww2.png';
import imgFive from './Images/ww3.png';
import imgSix from './Images/ww4.png';
import CardOne from './Images/CardOne.svg';
import CardTwo from './Images/CardTwo.svg';
import CardThree from './Images/CardThree.svg';
import CardEnhance from './Images/carEnhance.png';
import CardEnhance1 from './Images/CardEnhance1.jpeg';
import CardEnhance2 from './Images/carEnhance2.png';
import EditBtn from './Images/edit-button.svg';
import googleIcon from './Images/google-icon.svg';
import FcaebookIcon from './Images/facebookicon.svg';
import ContactUsRight from './Images/contact-us-right.png';
import SupportCardOne from './Images/support-card-one.svg';
import SupportCardTwo from './Images/support-card-two.svg';
import SupportCardThree from './Images/support-card-three.svg';
import PersonalInfoCard from './Images/personal-info-icon.svg';
import ProfilePicture from './Images/profile-picture.png';
import ThreeDots from './Images/ellipsis-h-icon.svg';
import notificationIcon from './Images/bell-line-icon.svg';
import ChnageProfileIcon from './Images/change-profile-icon.svg';
import filter1 from './Images/filter1 (1).png';
import filter2 from './Images/filter2.png';
import filter3 from './Images/filter3.png';
import filter4 from './Images/filter4.png';
import filter5 from './Images/filter5.png';
import filter from './Images/filter6.png';
import Amozon from './Images/amazonpay-logo-rgb_clr.svg';
import ButtomIcon from './Images/button-icon.svg';
import BankLogo from './Images/bank-logo.svg';
import CardIcon from './Images/card-icon.svg';
import SecurityLock from './Images/security-lock.png';
import PersonalInfoCardHover from './Images/personal-info-icon-hover.svg';
import slide1 from './Images/slide11.png'
import slide2 from './Images/slide12.png'
import slide3 from './Images/slide21.png'
import slide4 from './Images/slide22.png'
import slide5 from './Images/slide31.png'
import slide6 from './Images/slide32.png'
import EditIconWhite from './Images/edit-button-white.svg'
import ModalCross from './Images/modal-cross.svg'
import ShareWhite from './Images/Share-white.svg'
import AddCredit from './Images/add-credit.svg'
import cardCarOne from './Images/cardCarOne.png'
import cardCarTwo from './Images/cardCarTwo.png'
import cardCarThree from './Images/cardCarThree.png'
import DownloadWhite from './Images/download-white.svg'
import DownloadBlack from './Images/download-black.svg'

export default {
  DownloadWhite,
  DownloadBlack,
  cardCarOne,
  cardCarTwo,
  cardCarThree,
  AddCredit,
  ShareWhite,
  ModalCross,
  EditIconWhite,
  filter1,
  filter2,
  filter3,
  filter4,
  filter5,
  filter,
  imgOne,
  imgTwo,
  imgThree,
  imgFour,
  imgFive,
  imgSix,
  loader,
  afterImg,
  beforeImg,
  hide,
  eye,
  newEditCar,
  plate,
  newCar,
  Logo,
  FacbookLogo,
  GoogleLogo,
  FooterLogo,
  Loaction,
  FooterFb,
  FooterInsta,
  CarBefore,
  Carafter,
  Upload,
  Error,
  CarEhance,
  ErrorImage,
  RightImage,
  Cemara,
  PhoneHand,
  Car,
  Editcar,
  Share,
  Calender,
  GreenTick,
  CarBorder,
  ReadyCar,
  PhotoGraphy,
  TopView,
  ErrorIcon,
  ListIcon,
  PartnerShip,
  Visa,
  MasterCard,
  AmericanExpress,
  Discover,
  Venom,
  Paypal,
  userProfile,
  Star,
  AddIcon,
  Sparkle,
  SparklrCar,
  SliderImage,
  CarEnhanceTwo,
  CarEnhanceThree,
  PhotographyTip,
  image88,
  image103,
  image104,
  image107,
  image108,
  ForgotPassword,
  Key,
  ArrowRight,
  MobileViewImage,
  demoImg,
  editedDemoImg,
  pencil,
  CameraTry,
  CarTry,
  WhiteCarIcon,
  CardOne,
  CardTwo,
  CardThree,
  CardEnhance,
  CardEnhance1,
  CardEnhance2,
  EditBtn,
  ArrowRightWhite,
  googleIcon,
  FcaebookIcon,
  ContactUsRight,
  SupportCardOne,
  SupportCardTwo,
  SupportCardThree,
  PersonalInfoCard,
  ProfilePicture,
  ThreeDots,
  notificationIcon,
  ChnageProfileIcon,
  Amozon,
  ButtomIcon,
  BankLogo,
  CardIcon,
  SecurityLock,
  PersonalInfoCardHover,
  slide1,slide2,slide3,slide4,slide5,slide6
};
