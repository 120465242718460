import {
  filterChrome,
  filterCold,
  filterFade,
  filterMonoDefault,
  filterMonoNoir,
  filterMonoStark,
  filterMonoWash,
  filterPastel,
  filterSepiaBlues,
  filterSepiaColor,
  filterSepiaDefault,
  filterSepiaRust,
  filterWarm,
} from '@pqina/pintura';
import {
  EditorImageFilter,
  EditorImageFineTunes,
} from '../../../../Interfaces';
import { BackgroundImage } from '../../../../Types';

export enum EditorAccordions {
  BackgroundSettings = 'Background Settings',
  StudioSettings = 'Studio Settings',
  AdditionalSettings = 'Additional Settings',
}

const outsideFilters: BackgroundImage[] = [
  {
    category: 'outside',
    url: 'https://nyc3.digitaloceanspaces.com/assets.karstudio/static/image%2027.png',
  },
  {
    category: 'outside',
    url: 'https://nyc3.digitaloceanspaces.com/assets.karstudio/static/image%2030.png',
  },
  {
    category: 'outside',
    url: 'https://nyc3.digitaloceanspaces.com/assets.karstudio/static/image%2037.png',
  },
  {
    category: 'outside',
    url: 'https://nyc3.digitaloceanspaces.com/assets.karstudio/static/image%2040.png',
  },
  {
    category: 'outside',
    url: 'https://nyc3.digitaloceanspaces.com/assets.karstudio/static/image%2029.png',
  },
  {
    category: 'outside',
    url: 'https://nyc3.digitaloceanspaces.com/assets.karstudio/static/image.png',
  },
  {
    category: 'outside',
    url: 'https://nyc3.digitaloceanspaces.com/assets.karstudio/static/image%2042.png',
  },
  {
    category: 'outside',
    url: 'https://nyc3.digitaloceanspaces.com/assets.karstudio/static/image%2038%20%281%29.png',
  },
  {
    category: 'outside',
    url: 'https://nyc3.digitaloceanspaces.com/assets.karstudio/static/image%2035%20%281%29.png',
  },
  {
    category: 'outside',
    url: 'https://nyc3.digitaloceanspaces.com/assets.karstudio/static/image%2026.png',
  },
  {
    category: 'outside',
    url: 'https://nyc3.digitaloceanspaces.com/assets.karstudio/static/image%2032.png',
  },
  {
    category: 'outside',
    url: 'https://nyc3.digitaloceanspaces.com/assets.karstudio/static/image%2033%20%281%29.png',
  },
];

const studioFilters: BackgroundImage[] = [
  {
    category: 'studio',
    url: 'https://nyc3.digitaloceanspaces.com/assets.karstudio/static/car%20studio%20background%20with%20round%20stage%201.png',
  },
  {
    category: 'studio',
    url: 'https://nyc3.digitaloceanspaces.com/assets.karstudio/static/image%201%20%281%29.png',
  },
  {
    category: 'studio',
    url: 'https://nyc3.digitaloceanspaces.com/assets.karstudio/static/image%203%20%281%29.png',
  },
  {
    category: 'studio',
    url: 'https://nyc3.digitaloceanspaces.com/assets.karstudio/static/image%206.png',
  },
  {
    category: 'studio',
    url: 'https://nyc3.digitaloceanspaces.com/assets.karstudio/static/image%207%20%281%29.jpg',
  },
  {
    category: 'studio',
    url: 'https://nyc3.digitaloceanspaces.com/assets.karstudio/static/image%2012.png',
  },
  {
    category: 'studio',
    url: 'https://nyc3.digitaloceanspaces.com/assets.karstudio/static/image%2020.png',
  },
  {
    category: 'studio',
    url: 'https://nyc3.digitaloceanspaces.com/assets.karstudio/static/image%2023.png',
  },
  {
    category: 'studio',
    url: 'https://nyc3.digitaloceanspaces.com/assets.karstudio/static/image%2022.png',
  },
  {
    category: 'studio',
    url: 'https://nyc3.digitaloceanspaces.com/assets.karstudio/static/image%2019.png',
  },
  {
    category: 'studio',
    url: 'https://nyc3.digitaloceanspaces.com/assets.karstudio/static/image%209.png',
  },
  {
    category: 'studio',
    url: 'https://nyc3.digitaloceanspaces.com/assets.karstudio/static/image%2018.png',
  },
  {
    category: 'studio',
    url: 'https://nyc3.digitaloceanspaces.com/assets.karstudio/static/image%2011%20%282%29.png',
  },
  {
    category: 'studio',
    url: 'https://nyc3.digitaloceanspaces.com/assets.karstudio/static/image%205.png',
  },
  {
    category: 'studio',
    url: 'https://nyc3.digitaloceanspaces.com/assets.karstudio/static/image%202.png',
  },
];

const garageFilters: BackgroundImage[] = [
  {
    category: 'garage',
    url: 'https://nyc3.digitaloceanspaces.com/assets.karstudio/static/car%20studio%20backgrounds%20without%20car%209.png',
  },
  {
    category: 'garage',
    url: 'https://nyc3.digitaloceanspaces.com/assets.karstudio/static/car%20studio%20backgrounds%20without%20car.png',
  },
  {
    category: 'garage',
    url: 'https://nyc3.digitaloceanspaces.com/assets.karstudio/static/car%20studio%20backgrounds%20without%20car3.png',
  },
  {
    category: 'garage',
    url: 'https://nyc3.digitaloceanspaces.com/assets.karstudio/static/car%20studio%20backgrounds%20without%20car10.png',
  },
  {
    category: 'garage',
    url: 'https://nyc3.digitaloceanspaces.com/assets.karstudio/static/showroom%20backgrounds%20without%20cars%201.png',
  },
  {
    category: 'garage',
    url: 'https://nyc3.digitaloceanspaces.com/assets.karstudio/static/I%20need%20car%20studio%20backgrounds%20that%20look%20completely%20real%20and%20luxurious%2C%20without%20any%20cars%E2%80%94%20only%20the%C2%A0backgrounds.%201.png',
  },
  {
    category: 'garage',
    url: 'https://nyc3.digitaloceanspaces.com/assets.karstudio/static/car%20studio%20backgrounds%20without%20car%2012.png',
  },
  {
    category: 'garage',
    url: 'https://nyc3.digitaloceanspaces.com/assets.karstudio/static/car%20studio%20backgrounds%20without%20car.png',
  },
  {
    category: 'garage',
    url: 'https://nyc3.digitaloceanspaces.com/assets.karstudio/static/car%20studio%20backgrounds%20without%20car1.png',
  },
  {
    category: 'garage',
    url: 'https://nyc3.digitaloceanspaces.com/assets.karstudio/static/car%20studio%20backgrounds%20without%20car%2013.jpg',
  },
  {
    category: 'garage',
    url: 'https://nyc3.digitaloceanspaces.com/assets.karstudio/static/car%20studio%20backgrounds%20without%20car4%20%281%29.png',
  },
  {
    category: 'garage',
    url: 'https://nyc3.digitaloceanspaces.com/assets.karstudio/static/image%2046.png',
  },
  {
    category: 'garage',
    url: 'https://nyc3.digitaloceanspaces.com/assets.karstudio/static/image%2043.png',
  },
  {
    category: 'garage',
    url: 'https://nyc3.digitaloceanspaces.com/assets.karstudio/static/I%20need%20car%20studio%20backgrounds%20that%20look%20completely%20real%20and%20luxurious%2C%20without%20any%20cars%E2%80%94only%20the%C2%A0backgrounds.%202.png',
  },
  {
    category: 'garage',
    url: 'https://nyc3.digitaloceanspaces.com/assets.karstudio/static/image%2047.png',
  },
];

export const backgroundImages: BackgroundImage[] = [
  ...outsideFilters,
  ...studioFilters,
  ...garageFilters,
];

export const defaultEditorImageFineTunes: EditorImageFineTunes = {
  brightness: { label: 'Brightness', minValue: 0, maxValue: 200, value: 100 },
  contrast: { label: 'Contrast', minValue: 0, maxValue: 200, value: 100 },
  saturation: { label: 'Saturation', minValue: 0, maxValue: 300, value: 100 },
  exposure: { label: 'Exposure', minValue: -50, maxValue: 50, value: 0 },
  temperature: {
    label: 'Temperature',
    minValue: -100,
    maxValue: 100,
    value: 0,
  },
  //gamma: { label: 'Gamma', minValue: 0.5, maxValue: 2, value: 1 },
  clarity: { label: 'Clarity', minValue: 0, maxValue: 100, value: 0 },
};

export const editorImageFilters: EditorImageFilter[] = [
  { name: 'Default', value: filterMonoDefault() },
  { name: 'Chrome', value: filterChrome() },
  { name: 'Fade', value: filterFade() },
  { name: 'Cold', value: filterCold() },
  { name: 'Warm', value: filterWarm() },
  // { name: 'Invert', value: filterInvert() },
  { name: 'Mono', value: filterMonoDefault() },
  { name: 'Mono Noir', value: filterMonoNoir() },
  { name: 'Mono Stark', value: filterMonoStark() },
  { name: 'Mono Wash', value: filterMonoWash() },
  { name: 'Pastel', value: filterPastel() },
  { name: 'Sepia', value: filterSepiaDefault() },
  { name: 'Sepia Blues', value: filterSepiaBlues() },
  { name: 'Sepia Color', value: filterSepiaColor() },
  { name: 'Sepia Rust', value: filterSepiaRust() },
];
