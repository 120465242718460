import { FC, memo, ReactNode, useCallback, useMemo, useState } from 'react';
import { EditorAccordionBaseProps } from '../../../Interfaces';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import {
  EditorImageFilter,
  EditorImageFineTune,
  EditorProject,
} from '../../../../../../Interfaces';
import { Button, Slider, Tabs, TabsProps } from 'antd';
import {
  defaultEditorImageFineTunes,
  editorImageFilters,
} from '../../../Constants';
import { EditorFineTuneNames } from '../../../../../../Types';
import {
  createDefaultImageReader,
  createDefaultImageWriter,
  PinturaEditorHeadlessOptions,
  processImage,
} from '@pqina/pintura';
import FilterPreview from './FilterPreview/filterPreview';

enum AdditionalSettingsTabs {
  FINE_TUNE = 'Fine Tune',
  FILTERS = 'Filters',
  FLIP = 'Flip',
}

interface Props extends EditorAccordionBaseProps {
  selectedImgIndex: number;
  inEditProject: EditorProject | null;
  setLoading: (loading: boolean) => void;
  updateInEditProject: (project: EditorProject) => void;
}

const AdditionalSettings: FC<Props> = ({
  expanded,
  selectedImgIndex,
  inEditProject,
  onExpandChange,
  updateInEditProject,
  setLoading,
}) => {
  const [activeTab, setActiveTab] = useState<AdditionalSettingsTabs>(
    AdditionalSettingsTabs.FINE_TUNE
  );
  const [selectedFineTuneName, setSelectedFineTuneName] =
    useState<EditorFineTuneNames | null>(null);

  const onFineTuneValueChange = useCallback(
    (value: number) => {
      const updatedImages = [...inEditProject!.images];

      switch (selectedFineTuneName) {
        case 'Brightness':
          updatedImages[selectedImgIndex].fineTunes.brightness.value = value;
          break;
        case 'Clarity':
          updatedImages[selectedImgIndex].fineTunes.clarity.value = value;
          break;
        case 'Contrast':
          updatedImages[selectedImgIndex].fineTunes.contrast.value = value;
          break;
        case 'Exposure':
          updatedImages[selectedImgIndex].fineTunes.exposure.value = value;
          break;
        case 'Saturation':
          updatedImages[selectedImgIndex].fineTunes.saturation.value = value;
          break;
        case 'Temperature':
          updatedImages[selectedImgIndex].fineTunes.temperature.value = value;
          break;
        // case 'Gamma':
        //   updatedImages[selectedImgIndex].fineTunes.gamma.value = value;
        //   break;
      }

      updateInEditProject({ ...inEditProject!, images: updatedImages });
    },
    [inEditProject, selectedFineTuneName, selectedImgIndex, updateInEditProject]
  );

  const processImageUsingPintura = useCallback(
    async (flipX?: boolean, flipY?: boolean) => {
      if (flipX === undefined && flipY === undefined) {
        return;
      }

      setLoading(true);

      try {
        const options: PinturaEditorHeadlessOptions = {
          imageReader: createDefaultImageReader(),
          imageWriter: createDefaultImageWriter(),
          imageFlipX: flipX,
          imageFlipY: flipY,
        };

        const imageWriterResult = await processImage(
          inEditProject!.images[selectedImgIndex].carImageUrlBase64,
          options
        );

        if (imageWriterResult.dest) {
          const reader = new FileReader();

          reader.onloadend = () => {
            const updatedImages = [...inEditProject!.images];
            updatedImages[selectedImgIndex].pinturaEditedImageBase64 =
              reader.result as string;

            updatedImages[selectedImgIndex].flipX = flipX!;
            updatedImages[selectedImgIndex].flipY = flipY!;
            updateInEditProject({ ...inEditProject!, images: updatedImages });
          };

          reader.readAsDataURL(imageWriterResult.dest);
        }
      } catch (err) {
      } finally {
        setLoading(false);
      }
    },
    [inEditProject, setLoading, selectedImgIndex, updateInEditProject]
  );

  const onFilterChange = useCallback(
    async (filteredImage: string, filter: EditorImageFilter) => {
      const updatedImages = [...inEditProject!.images];

      if (filteredImage.trim() !== '') {
        updatedImages[selectedImgIndex].pinturaEditedImageBase64 =
          filteredImage;
      }

      updatedImages[selectedImgIndex].appliedFilter = filter!;
      updateInEditProject({ ...inEditProject!, images: updatedImages });
    },
    [inEditProject, selectedImgIndex, updateInEditProject]
  );

  const onFlipXChange = useCallback(async () => {
    const inEditImage = inEditProject!.images[selectedImgIndex];

    await processImageUsingPintura(!inEditImage.flipX, inEditImage.flipY);
  }, [inEditProject, selectedImgIndex, processImageUsingPintura]);

  const onFlipYChange = useCallback(async () => {
    const inEditImage = inEditProject!.images[selectedImgIndex];

    await processImageUsingPintura(inEditImage.flipX, !inEditImage.flipY);
  }, [inEditProject, selectedImgIndex, processImageUsingPintura]);

  const FineTunes = useMemo<ReactNode>(() => {
    const fineTunes = inEditProject
      ? inEditProject.images[selectedImgIndex].fineTunes
      : defaultEditorImageFineTunes;

    const imageFineTuneData = selectedFineTuneName
      ? ((defaultEditorImageFineTunes as any)[
          selectedFineTuneName.toLowerCase()
        ] as EditorImageFineTune)
      : null;

    return (
      <div className="finetune-section">
        {selectedFineTuneName && (
          <Slider
            value={imageFineTuneData!.value}
            tooltip={{ open: false, destroyTooltipOnHide: true }}
            min={imageFineTuneData!.minValue}
            max={imageFineTuneData!.maxValue}
            onChange={(value) => onFineTuneValueChange(value)}
          />
        )}

        <div className="finetune-section__options">
          {Object.keys(fineTunes).map((x) => {
            const fineTune = (fineTunes as any)[x] as EditorImageFineTune;

            return (
              <div
                key={fineTune.label}
                className={`finetune-section__option ${
                  selectedFineTuneName === fineTune.label &&
                  'finetune-section__option-active'
                }`}
                onClick={() => setSelectedFineTuneName(fineTune.label)}
              >
                {fineTune.label}
              </div>
            );
          })}
        </div>
      </div>
    );
  }, [
    selectedFineTuneName,
    inEditProject,
    selectedImgIndex,
    onFineTuneValueChange,
    setSelectedFineTuneName,
  ]);

  const Filters = useMemo<ReactNode>(() => {
    const selectedFilter = inEditProject
      ? inEditProject.images[selectedImgIndex].appliedFilter
      : null;

    return (
      <div className="additional-settings-box">
        <div className="additional-settings">
          {editorImageFilters.map((x) => (
            <FilterPreview
              filter={x}
              onClick={onFilterChange}
              isSelected={selectedFilter?.name === x.name}
              selectedImageUrl={
                inEditProject
                  ? inEditProject.images[selectedImgIndex].carImageUrlBase64
                  : null
              }
            />
          ))}
        </div>
      </div>
    );
  }, [inEditProject, selectedImgIndex, onFilterChange]);

  const Flip = useMemo<ReactNode>(() => {
    return (
      <div className="car-position">
        <Button color="primary" onClick={() => onFlipXChange()}>
          <p className="image-filter-preview">Vertical</p>
        </Button>
        <Button color="primary" onClick={() => onFlipYChange()}>
          <p className="image-filter-preview">Horizontal</p>
        </Button>
      </div>
    );
  }, [onFlipXChange, onFlipYChange]);

  const tabItems = useMemo<TabsProps['items']>(() => {
    return [
      {
        key: AdditionalSettingsTabs.FINE_TUNE,
        label: AdditionalSettingsTabs.FINE_TUNE,
        children: FineTunes,
      },
      {
        key: AdditionalSettingsTabs.FILTERS,
        label: AdditionalSettingsTabs.FILTERS,
        children: Filters,
      },
      {
        key: AdditionalSettingsTabs.FLIP,
        label: AdditionalSettingsTabs.FLIP,
        children: Flip,
      },
    ];
  }, [FineTunes, Filters, Flip]);

  return (
    <Accordion
      expanded={expanded}
      onChange={onExpandChange}
      className="editor-accordion"
    >
      <AccordionSummary>
        <Typography component="span">Additional Settings</Typography>
      </AccordionSummary>

      <AccordionDetails className="editor-nav-tabs">
        <Tabs
          centered
          items={tabItems}
          activeKey={activeTab}
          onChange={(key) => setActiveTab(key as AdditionalSettingsTabs)}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(AdditionalSettings);
