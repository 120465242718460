import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AdminAuthContextProvider } from './Modules/Admin/Contexts';
import { AuthContextProvider } from './Modules/Core/Contexts/AuthContext';
import { UserInfoProvider } from './Modules/Core/Contexts/UserInfoContext';
import { SeoMetaDataProvider } from './Modules/Core/Contexts/SeoContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AdminAuthContextProvider>
        <AuthContextProvider>
          <UserInfoProvider>
            <SeoMetaDataProvider>
              <App />
            </SeoMetaDataProvider>
          </UserInfoProvider>
        </AuthContextProvider>
      </AdminAuthContextProvider>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
