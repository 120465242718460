import { FC, memo, useState } from 'react';

import { EditorAccordions } from '../../Constants';
import { BackgroundImage } from '../../../../../Types';
import StudioSettings from './StudioSettings/StudioSettings';
import BackgroundSettings from './BackgroundSettings/BackgroundSettings';
import AdditionalSettings from './AdditionalSettings/AdditionalSettings';
import { EditorProject } from '../../../../../Interfaces';

interface Props {
  disabled: boolean;
  selectedImgIndex: number;
  inEditProject: EditorProject | null;
  selectedBackground: BackgroundImage | null;
  setLoading: (loading: boolean) => void;
  updateInEditProject: (project: EditorProject) => void;
  onBackgroundChange: (background: BackgroundImage) => void;
}

const EditorOptions: FC<Props> = ({
  disabled,
  inEditProject,
  selectedBackground,
  selectedImgIndex,
  onBackgroundChange,
  updateInEditProject,
  setLoading,
}) => {
  const [openedAccordion, setOpenedAccordion] = useState<EditorAccordions>(
    EditorAccordions.BackgroundSettings
  );

  return (
    <div className="mainBox">
      <BackgroundSettings
        disabled={disabled}
        selectedBackground={selectedBackground}
        isCreatingNewProject={!inEditProject}
        onBackgroundChange={onBackgroundChange}
        expanded={openedAccordion === EditorAccordions.BackgroundSettings}
        onExpandChange={() =>
          !disabled && setOpenedAccordion(EditorAccordions.BackgroundSettings)
        }
      />

      <StudioSettings
        selectedImgIndex={selectedImgIndex}
        inEditProject={inEditProject}
        expanded={openedAccordion === EditorAccordions.StudioSettings}
        onExpandChange={() =>
          !disabled && setOpenedAccordion(EditorAccordions.StudioSettings)
        }
        updateInEditProject={updateInEditProject}
        setOpenedAccordion={setOpenedAccordion}
      />

      <AdditionalSettings
        selectedImgIndex={selectedImgIndex}
        inEditProject={inEditProject}
        updateInEditProject={updateInEditProject}
        expanded={openedAccordion === EditorAccordions.AdditionalSettings}
        onExpandChange={() =>
          !disabled && setOpenedAccordion(EditorAccordions.AdditionalSettings)
        }
        setLoading={setLoading}
      />
    </div>
  );
};

export default memo(EditorOptions);
