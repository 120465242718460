import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import { OverlaySpinner } from '../Shared';

type AuthUser = {
  email: string;
  username: string;
};

type AdminAuthContextType = {
  token?: string | null;
  authUser?: AuthUser | null;
  tokenType?: string | null;
  isAuthenticated: boolean;
  //adminIp: string;
  login: (token: any, user: AuthUser) => void;
  logout: () => void;
};

const AdminAuthContext = createContext({} as AdminAuthContextType);

const AdminAuthContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [tokenData, setTokenData] = useState<any | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [authUser, setAuthUser] = useState<AuthUser | null>();
  // const adminIp = '2404:7c80:4c:2e28:acda:a7c8:d10b:4ed2'; // IPv6

  useEffect(() => {
    continueWithSavedToken();
  }, []);

  const continueWithSavedToken = (): void => {
    const savedAuthToken = localStorage.getItem('adminToken');
    const userJson = localStorage.getItem('adminUser');

    if (!savedAuthToken || !userJson || userJson.trim() === '') {
      setIsLoading(false);
      localStorage.removeItem('adminToken');
      localStorage.removeItem('adminUser');

      return;
    }

    setTokenData(savedAuthToken);
    setAuthUser(JSON.parse(userJson));
    setIsAuthenticated(true);
    setIsLoading(false);
  };

  const loginUser = (newToken: string, user: AuthUser): void => {
    try {
      localStorage.setItem('adminToken', newToken);
      localStorage.setItem('adminUser', JSON.stringify(user));

      // const tokenClaims = parseJwt<any>(tokenData.Token);

      // if (tokenClaims) {
      //   const user = new User(tokenClaims);

      setIsAuthenticated(true);
      setTokenData(tokenData);
      setIsLoading(false);
      setAuthUser(user);
      // }

      setIsAuthenticated(true);
      setIsLoading(false);
    } catch {
      localStorage.clear();
    } finally {
      if (isLoading) {
        setIsLoading(false);
      }
    }
  };

  const logoutUser = (): void => {
    setIsAuthenticated(false);
    setTokenData(null);
    setAuthUser(null);

    localStorage.clear();
  };

  if (isLoading) {
    return <OverlaySpinner isSpinning />;
  }

  return (
    <AdminAuthContext.Provider
      value={{
        //adminIp,
        authUser,
        isAuthenticated,
        login: loginUser,
        logout: logoutUser,
        token: tokenData?.Token,
        tokenType: tokenData?.TokenType,
      }}
    >
      {children}
    </AdminAuthContext.Provider>
  );
};

const useAdminAuthentication = (): AdminAuthContextType => {
  const context = useContext(AdminAuthContext);

  if (!context) {
    throw new Error(
      'useAuthentication must be used within an AdminAuthContextProvider'
    );
  }

  return context;
};

export { AdminAuthContextProvider, useAdminAuthentication };
