import { appRoutes } from './Routes';
import { ToastContainer } from 'react-toastify';
import { Route, Routes } from 'react-router-dom';

function App() {
  return (
    <>
      <ToastContainer />
      <Routes>
        {appRoutes.map((route) => (
          <Route
            key={route.key}
            path={route.path}
            element={
              route.LazyComponent ? <route.LazyComponent /> : <>No Content</>
            }
          />
        ))}
      </Routes>
    </>
  );
}

export default App;
