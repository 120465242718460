import React from 'react';
import { Modal, Box, Typography, Button, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Images from '../../../Images/Images'

interface CustomModalProps {
  open: boolean;
  handleClose: () => void;
}
const CustomModal: React.FC<CustomModalProps> = ({ open, handleClose }) => {
  const navigate = useNavigate();
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      BackdropProps={{
        sx: { backdropFilter: 'blur(5px)' }, // Adds blur effect
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 401,
          bgcolor: 'white',
          boxShadow: 24,
          p: 3,
          borderRadius: 2,
          textAlign: 'center',
        }}
        className='modal-credit-main'
      >
        {/* Close Button */}
        <IconButton
          className='close-button'
          onClick={handleClose}
          aria-label="close"
        >
          {/* <Image src={Images.ModalCross} alt="Close Modal" className="img-fluid" /> */}
        </IconButton>

        {/* Modal Content */}
        <Typography id="modal-title" className='modal-heading-main'>
          You Currently Have 0 Credits.
        </Typography>
        <Typography id="modal-description" className='modal-heading-main'>
          Add Credits Now.
        </Typography>

        {/* Add Credits Button */}
        <Button
          variant="outlined"
          className='add-credit-button-modal'
          onClick={() => navigate('/plans')}
        >
          Add Credits  <img src={Images.AddCredit} alt="AddCredit" className="img-fluid AddCredit" />
        </Button>
      </Box>
    </Modal>
  );
};

export default CustomModal;
