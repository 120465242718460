import Backdrop from '@mui/material/Backdrop';
import { FC } from 'react';
import loader from '../../../Images/Loader/rotate 1.gif';

const OverlayLoader: FC = () => {
  return (
    <Backdrop
      sx={(theme) => ({
        color: '#fff',
        zIndex: theme.zIndex.drawer + 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      })}
      open={true}
    >
      <img src={loader} alt="Loading..." width={80} height={80} />
    </Backdrop>
  );
};

export default OverlayLoader;
