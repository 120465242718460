import { CSSProperties, FC, memo, useEffect, useMemo, useState } from 'react';
import { EditorImageFilter } from '../../../../../../../Interfaces';
import {
  createDefaultImageReader,
  createDefaultImageWriter,
  PinturaEditorHeadlessOptions,
  processImage,
} from '@pqina/pintura';

interface Props {
  isSelected: boolean;
  filter: EditorImageFilter;
  selectedImageUrl: string | null;
  onClick: (filteredImage: string, filter: EditorImageFilter) => void;
}

const FilterPreview: FC<Props> = ({
  filter,
  isSelected,
  selectedImageUrl,
  onClick,
}) => {
  const [preview, setPreview] = useState<string | null>(null);

  useEffect(() => {
    if (selectedImageUrl) {
      const options: PinturaEditorHeadlessOptions = {
        imageReader: createDefaultImageReader(),
        imageWriter: createDefaultImageWriter(),
        imageColorMatrix:
          filter.name === 'Default' ? undefined : { filter: filter.value },
      };

      processImage(selectedImageUrl, options).then((imageWriterResult) => {
        if (imageWriterResult.dest) {
          const reader = new FileReader();

          reader.onloadend = () => {
            setPreview(reader.result as string);
          };

          reader.readAsDataURL(imageWriterResult.dest);
        }
      });
    }
  }, [selectedImageUrl, filter]);

  const styles = useMemo<CSSProperties | undefined>(() => {
    if (!preview) return undefined;

    return {
      backgroundImage: `url(${preview})`,
      objectFit: 'cover',
      backgroundSize: 'contain',
      cursor: 'pointer',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    };
  }, [preview]);

  return (
    <div
      className={`image-filter-preview ${
        isSelected || (!isSelected && filter.name === 'Default')
          ? 'image-filter-preview-active'
          : ''
      }`}
      onClick={() => onClick(preview ?? '', filter)}
      style={styles}
    >
      {/* {filter.name} */}
    </div>
  );
};

export default memo(FilterPreview);
