import { Modal, Spin } from 'antd';
import { FC } from 'react';

interface Props {
  isSpinning: boolean;
}

const OverlaySpinner: FC<Props> = ({ isSpinning }) => {
  return isSpinning ? (
    <Modal
      open
      centered
      modalRender={() => (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Spin size="large" spinning={true} />
        </div>
      )}
    ></Modal>
  ) : (
    <></>
  );
};

export default OverlaySpinner;
