import axios from 'axios';

const usersAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const loginUser = async (email: string, password: string) => {
  return usersAxios.post<any>('users/login', { email, password });
};

export const registerUser = async (
  username: string,
  email: string,
  password: string
) => {
  return usersAxios.post<any>('users/register', { username, email, password });
};

export const signInWithGoogle = async (
  username: string,
  email: string,
  profilePicture: string
) => {
  return usersAxios.post<any>('users/google-signin', {
    username,
    email,
    profilePicture,
  });
};

export const signInWithFb = async (
  username: string,
  email: string,
  profilePicture: string
) => {
  return usersAxios.post<any>('users/facebook-signin', {
    username,
    email,
    profilePicture,
  });
};

export const forgetPassword = async (email: string) => {
  return usersAxios.post<any>('users/forgot-password', { email });
};

export const resetPassword = async (token: string, newPassword: string) => {
  return usersAxios.post<any>('users/reset-password', { token, newPassword });
};
