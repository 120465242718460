import { FC, memo, useCallback, useState } from 'react';
import { EditorAccordionBaseProps } from '../../../Interfaces';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Radio,
  RadioGroup,
  Slider,
  Typography,
} from '@mui/material';
import { EditorProject } from '../../../../../../Interfaces';
import { EditorAccordions } from '../../../Constants';
import { EditorImageAspectRatio } from '../../../../../../Types';

interface Props extends EditorAccordionBaseProps {
  selectedImgIndex: number;
  inEditProject: EditorProject | null;
  setOpenedAccordion: (acc: EditorAccordions) => void;
  updateInEditProject: (project: EditorProject) => void;
}

const StudioSettings: FC<Props> = ({
  expanded,
  inEditProject,
  selectedImgIndex,
  onExpandChange,
  updateInEditProject,
  setOpenedAccordion,
}) => {
  const rotateImage = useCallback(
    (angle: number) => {
      const updatedImages = [...inEditProject!.images];
      updatedImages[selectedImgIndex].rotationAngle = angle;

      updateInEditProject({ ...inEditProject!, images: updatedImages });
    },
    [inEditProject, selectedImgIndex, updateInEditProject]
  );

  const handleAspectRatioChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const updatedImages = [...inEditProject!.images];
      updatedImages[selectedImgIndex].aspectRatio = event.target
        .value as EditorImageAspectRatio;

      updateInEditProject({ ...inEditProject!, images: updatedImages });
    },
    [inEditProject, selectedImgIndex, updateInEditProject]
  );

  return (
    <Accordion
      expanded={expanded}
      onChange={onExpandChange}
      className="editor-accordion Studio-settings"
    >
      <AccordionSummary>
        <Typography component="span">Studio Settings</Typography>
      </AccordionSummary>

      <AccordionDetails className="editor-nav-tabs">
        <Typography gutterBottom>Rotation</Typography>
        <Slider
          value={
            inEditProject
              ? inEditProject.images[selectedImgIndex].rotationAngle
              : 0
          }
          valueLabelDisplay="on"
          min={-180}
          max={180}
          step={1}
          aria-label="Rotation"
          onChange={(_, value) => rotateImage(value as number)}
        />

        <Typography gutterBottom>Aspect Ratio</Typography>
        <RadioGroup
          row
          value={
            inEditProject
              ? inEditProject.images[selectedImgIndex].aspectRatio || '16:9'
              : '16:9'
          }
          name="aspect-ratio"
          onChange={handleAspectRatioChange}
        >
          <FormControlLabel
            value="16:9"
            control={<Radio />}
            label="16:9"
            className={
              (inEditProject
                ? inEditProject.images[selectedImgIndex].aspectRatio || '16:9'
                : '16:9') === '16:9'
                ? 'active-radio'
                : ''
            }
          />
          <FormControlLabel
            value="8:5"
            control={<Radio />}
            label="8:5"
            className={
              (inEditProject
                ? inEditProject.images[selectedImgIndex].aspectRatio || '16:9'
                : '16:9') === '8:5'
                ? 'active-radio'
                : ''
            }
          />
          <FormControlLabel
            value="3:2"
            control={<Radio />}
            label="3:2"
            className={
              (inEditProject
                ? inEditProject.images[selectedImgIndex].aspectRatio || '16:9'
                : '16:9') === '3:2'
                ? 'active-radio'
                : ''
            }
          />
          <FormControlLabel
            value="4:3"
            control={<Radio />}
            label="4:3"
            className={
              (inEditProject
                ? inEditProject.images[selectedImgIndex].aspectRatio || '16:9'
                : '16:9') === '4:3'
                ? 'active-radio'
                : ''
            }
          />
        </RadioGroup>

        <button
          className="main-btn-filled mt-3 w-100"
          onClick={() =>
            setOpenedAccordion(EditorAccordions.AdditionalSettings)
          }
          disabled={!inEditProject}
        >
          Next : Additional Settings
        </button>
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(StudioSettings);
