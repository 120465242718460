import { FC, PropsWithChildren, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Button,
  Dropdown,
  Flex,
  Layout,
  Space,
  theme,
  Typography,
} from 'antd';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  PoweroffOutlined,
} from '@ant-design/icons';

import { SideBarMenu } from './SideBarMenu';
import { useAdminAuthentication, useAdminSnackBar } from '../../../Contexts';

const { Header, Sider, Content, Footer } = Layout;

const AppLayout: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const { showSnackBar } = useAdminSnackBar();
  const { isAuthenticated, authUser, logout } = useAdminAuthentication();
  const [collapsed, setCollapsed] = useState(false);
  const { colorBgContainer, borderRadiusLG } = theme.useToken().token;

  const logoutUser = (): void => {
    logout();
    showSnackBar('User Logged out!', 'success');
    navigate('admin/login');
  };

  return (
    <Layout style={{ height: '100vh', maxHeight: '100vh' }}>
      {isAuthenticated && (
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          breakpoint="lg"
          collapsedWidth="0"
        >
          <div
            className="demo-logo-vertical"
            style={{
              height: '32px',
              margin: '16px',
              background: '#ffffff33',
              borderRadius: '6px',
            }}
          >
            <h6 style={{ paddingLeft: '20px' }}>KarStudio Admin</h6>
          </div>

          <SideBarMenu />
        </Sider>
      )}
      <Layout style={{ height: '100vh', maxHeight: '100vh' }}>
        <Header style={{ padding: 0, background: colorBgContainer }}>
          <Flex justify="space-between" align="center">
            {isAuthenticated && (
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
                style={{
                  fontSize: '16px',
                  width: 64,
                  height: 64,
                }}
              />
            )}

            {isAuthenticated && (
              <Dropdown
                menu={{
                  items: [
                    {
                      key: 'Log out',
                      label: 'Log out',
                      icon: <PoweroffOutlined />,
                      onClick: logoutUser,
                    },
                  ],
                }}
              >
                <Flex align="center">
                  <Space>
                    <Typography.Text style={{ cursor: 'pointer' }}>
                      {authUser!.username}
                    </Typography.Text>
                    <Avatar
                      style={{
                        marginRight: '16px',
                        backgroundColor: '#adcfff',
                        color: '#1677ff',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                      }}
                      icon={<UserOutlined />}
                    />
                  </Space>
                </Flex>
              </Dropdown>
            )}
          </Flex>
        </Header>
        <Content
          style={{
            margin: '24px 16px 0px 16px',
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
            height: '100%',
          }}
        >
          {children}
        </Content>

        <Footer style={{ textAlign: 'center' }}>KarStudio Admin</Footer>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
