import axios from 'axios';

const usersAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const getRemainingCredits = async () => {
  const token = localStorage.getItem('token');

  return usersAxios.get<any>('/stripe/remaining-credits', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
