import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

import {
  createCarProject,
  DownloadedImages,
  S3FilesService,
  updateProject,
} from '../../../Services';
import OverlayLoader from '../OverlayLoader/OverlayLoader';
import { EditorOptions, ImagesSelector } from './Components';
import { BackgroundImage } from '../../../Types';
import { useSnackBar } from '../../../Contexts/SnackbarContext';
import { useAvailableCredits } from '../../../Contexts/AvailableCreditsContext';
import { EditorProject } from '../../../Interfaces';
import { mapCarProjectToEditorProject } from './CarImageEditorUtils';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { ResizableBox, ResizeCallbackData } from 'react-resizable';
import html2canvas from 'html2canvas';
import CustomModal from '../DialogBox/CreditPop-up';

interface Props {
  carProjectForEdit?: EditorProject | null;
}

const CarImageEditor: FC<Props> = ({ carProjectForEdit }) => {
  const { showSnackBar } = useSnackBar();
  const { refreshCredits } = useAvailableCredits();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedImages, setSelectedImages] = useState<File[]>([]);
  const [selectedImgIndex, setSelectedImgIndex] = useState<number>(0);
  const [uploadedImageUrls, setUploadedImageUrls] = useState<string[]>([]);
  const [selectedBackground, setSelectedBackground] =
    useState<BackgroundImage | null>(null);
  const [inEditProject, setInEditProject] = useState<EditorProject | null>(
    null
  );

  const imageEditorRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (carProjectForEdit) {
      initForEditMode();
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carProjectForEdit]);

  useEffect(() => {
    if (
      imageEditorRef &&
      inEditProject &&
      !inEditProject.isInitialResizeAndPositioningCompleted
    ) {
      const editorWidth = imageEditorRef.current!.getBoundingClientRect().width;
      const editorHeight =
        imageEditorRef.current!.getBoundingClientRect().height;

      const width = (editorWidth * 70) / 100;
      const height = (editorHeight * 80) / 100;

      const updatedImages = [...inEditProject!.images];
      updatedImages.forEach((x) => {
        x.size = { width, height };
        x.position = {
          x: editorWidth / 2 - width / 2,
          y: editorHeight / 2 - height / 2,
        };
      });

      setInEditProject({
        ...inEditProject!,
        images: updatedImages,
        isInitialResizeAndPositioningCompleted: true,
      });
    }
  }, [imageEditorRef, inEditProject, setInEditProject]);

  const initForEditMode = useCallback(async () => {
    setInEditProject(carProjectForEdit!);
    setSelectedBackground(carProjectForEdit!.background);
  }, [carProjectForEdit]);

  const createNewProjectAndInitEditor = useCallback(
    async (background: BackgroundImage) => {
      if (!isLoading) setIsLoading(true);

      //const oldProcessedImages = [...processedImagesUrls];

      try {
        const { data } = await createCarProject(
          uploadedImageUrls,
          background.url
        );

        const mappedProject = await mapCarProjectToEditorProject(
          data.carImages
        );

        setInEditProject(mappedProject);
        refreshCredits();
      } catch (error: any) {
        showSnackBar(
          error.response.data.error
            ? error.response.data.error
            : error.response.data.message,
          'warning'
        );
        return;
      } finally {
        setIsLoading(false);
      }
    },
    [isLoading, uploadedImageUrls, showSnackBar, refreshCredits]
  );

  const onBackgroundChange = useCallback(
    async (background: BackgroundImage) => {
      setSelectedBackground(background);

      if (!inEditProject) {
        createNewProjectAndInitEditor(background);
        return;
      }

      setIsLoading(true);

      const { carStudioId, images } = inEditProject!;
      const imageUrls = images.map((x) => x.processedImageUrl);

      try {
        const { data } = await updateProject(
          carStudioId,
          imageUrls,
          background.url
        );

        const updatedProject = await mapCarProjectToEditorProject(
          data.carImages
        );

        setInEditProject(updatedProject);
      } catch (error: any) {
        showSnackBar(error.response.data.error, 'error');
        return;
      } finally {
        setIsLoading(false);
      }
    },
    [inEditProject, showSnackBar, createNewProjectAndInitEditor]
  );

  const uploadFilesToS3 = useCallback(
    async (files: File[]) => {
      if (!isLoading) setIsLoading(true);

      const uploadPromises = files.map((file) => S3FilesService.upload(file));
      const uploadedUrls = (await Promise.all(uploadPromises)).filter(
        (url) => url !== null
      ) as string[];

      setIsLoading(false);
      setUploadedImageUrls(uploadedUrls);
    },
    [isLoading]
  );

  const onImagesSelected = useCallback(
    async (imageFiles: File[]) => {
      if (imageFiles.length > 0) {
        const files = Array.from(imageFiles);

        setIsLoading(true);
        setSelectedImages(files);

        // Upload Images To S3
        await uploadFilesToS3(files);
      }
    },
    [uploadFilesToS3]
  );

  const downloadSelectedImage = useCallback(async () => {
    const editorElement = document.getElementById('editor-preview');

    if (!editorElement) return;

    setIsLoading(true);

    // Hide resizable handles before capturing
    const resizeHandles = document.querySelectorAll('.react-resizable-handle');
    resizeHandles.forEach(
      (handle) => ((handle as HTMLElement).style.display = 'none')
    );

    const canvas = await html2canvas(editorElement, {
      useCORS: true,
      backgroundColor: null,
    });

    const image = canvas.toDataURL('image/png');

    // Restore resizable handles
    resizeHandles.forEach(
      (handle) => ((handle as HTMLElement).style.display = '')
    );

    // Create a download link
    const link = document.createElement('a');
    link.href = image;
    link.download = 'exported-image.png';
    link.click();

    // try {
    //   // Call the API after download
    //   await DownloadedImages([image]);
    // } catch (error) {
    // } finally {
    //   setIsLoading(false);
    // }
  }, []);

  const handleDrag = useCallback(
    (e: DraggableEvent, data: DraggableData) => {
      const updatedImages = [...inEditProject!.images];
      updatedImages[selectedImgIndex].position = data;

      setInEditProject({ ...inEditProject!, images: updatedImages });
    },
    [inEditProject, selectedImgIndex, setInEditProject]
  );

  const handleResize = useCallback(
    (data: ResizeCallbackData) => {
      const centerX =
        imageEditorRef.current!.getBoundingClientRect().width / 2 -
        data.size.width / 2;
      const centerY =
        imageEditorRef.current!.getBoundingClientRect().height / 2 -
        data.size.height / 2;

      const updatedImages = [...inEditProject!.images];
      updatedImages[selectedImgIndex].size = data.size;
      updatedImages[selectedImgIndex].position = { x: centerX, y: centerY };

      setInEditProject({ ...inEditProject!, images: updatedImages });
    },
    [inEditProject, selectedImgIndex, imageEditorRef, setInEditProject]
  );

  const imagesUrlsForSlider = useMemo<string[]>(() => {
    if (inEditProject) {
      return inEditProject.images.map((x) => x.processedImageUrl);
    }

    if (selectedImages.length > 0) {
      return selectedImages.map((x) => URL.createObjectURL(x));
    }

    return [];
  }, [selectedImages, inEditProject]);

  const EditorPreview = useMemo<ReactNode>(() => {
    let inEditImageUrl: string | null = null;

    if (inEditProject && inEditProject.images.length > 0) {
      const selectedImg = inEditProject.images[selectedImgIndex];

      if (
        selectedImg.pinturaEditedImageBase64 &&
        selectedImg.pinturaEditedImageBase64.trim() !== ''
      ) {
        inEditImageUrl = selectedImg.pinturaEditedImageBase64!;
      } else {
        inEditImageUrl = selectedImg.carImageUrlBase64;
      }
    }

    const uploadedImageUrl =
      selectedImages.length > 0
        ? URL.createObjectURL(selectedImages[selectedImgIndex])
        : null;

    if (inEditImageUrl) {
      const selectedImg = inEditProject!.images[selectedImgIndex];
      const {
        brightness,
        clarity,
        contrast,
        exposure,
        saturation,
        temperature,
      } = selectedImg.fineTunes;

      return (
        <div
          id="editor-preview"
          className="editor-box-resizeable"
          ref={imageEditorRef}
          style={{
            width: '100%',
            height: '100%',
            backgroundImage: `url("${selectedBackground?.url}")`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            position: 'relative',
            borderRadius: '25px',
            overflow: 'hidden',
          }}
        >
          <Draggable
            position={selectedImg.position}
            onDrag={handleDrag}
            axis="both"
            cancel={'.react-resizable-handle'}
          >
            <div
              style={{
                position: 'relative',
              }}
            >
              <ResizableBox
                width={selectedImg.size.width}
                height={selectedImg.size.height}
                axis="both"
                onResizeStop={(e, data) => handleResize(data)}
                className="hello-moto"
                resizeHandles={['se', 'sw', 'ne', 'nw']}
              >
                <div
                  className="resizeable-box"
                  style={{
                    width: '100%',
                    height: '100%',
                    cursor: 'move',
                  }}
                >
                  <div
                    className="icon-moto"
                    style={{
                      // width: `${calculatedWidth}px`,
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      background: 'transparent',
                      backgroundImage: `url(${inEditImageUrl})`,
                      objectFit: 'cover',
                      backgroundSize: 'contain',
                      cursor: 'move',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      transform: `rotate(${selectedImg.rotationAngle}deg)`,
                      position: 'relative',
                      filter: `brightness(${
                        brightness.value + exposure.value
                      }%) contrast(${
                        contrast.value + clarity.value
                      }%) saturate(${saturation.value}%) sepia(${
                        temperature.value / 2
                      }%)`,
                    }}
                  />
                </div>
              </ResizableBox>
            </div>
          </Draggable>
        </div>
      );
    }

    if (uploadedImageUrl) {
      return (
        <img
          alt="Uploaded Preview"
          className="preview-img"
          src={uploadedImageUrl!}
        />
      );
    }

    return <ImagesSelector onSelect={onImagesSelected} />;
  }, [
    inEditProject,
    selectedBackground,
    selectedImages,
    selectedImgIndex,
    onImagesSelected,
    handleDrag,
    handleResize,
  ]);

  const imageScreenSize = useMemo(() => {
    let screenWidth = (window.innerWidth * 70) / 100;

    if (imageEditorRef.current) {
      const editorRect = imageEditorRef.current.getBoundingClientRect();
      screenWidth = editorRect.width;
    }
    const selectedRatio =
      inEditProject?.images[selectedImgIndex].aspectRatio || '16:9';

    // Calculate the dynamic height
    const calculatedHeight = Math.round(
      screenWidth / aspectRatios[selectedRatio]
    );

    return {
      width: screenWidth,
      height: calculatedHeight,
    };
  }, [imageEditorRef, selectedImgIndex, inEditProject]);

  return (
    <>
      {isLoading && <OverlayLoader />}

      <section className="editor-deatils">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 editor-left-bg">
              <div
                className="file-upload-container mb-4"
                style={{
                  minHeight: `${imageScreenSize.height}px`,
                  height: `${imageScreenSize.height}px`,
                }}
              >
                {EditorPreview}
              </div>

              {imagesUrlsForSlider.length > 0 && (
                <Swiper
                  modules={[Navigation, Pagination, Autoplay]}
                  spaceBetween={0}
                  slidesPerView={3}
                  navigation
                  pagination={false}
                  autoplay={{ delay: 3000 }}
                  loop={true}
                >
                  {imagesUrlsForSlider.map((image, index) => (
                    <SwiperSlide key={index}>
                      <div className="decoration-car">
                        <div
                          key={image}
                          className={`pic-img ${
                            selectedImgIndex === index ? 'active' : ''
                          }`}
                          onClick={() => setSelectedImgIndex(index)}
                          style={{ cursor: 'pointer' }}
                        >
                          <img alt="test" loading="lazy" src={image} />
                        </div>
                        {/* <img
                          src={Images.ErrorIcon}
                          alt="cross-icon"
                          className="img-fluid cross-icon"
                        /> */}
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              )}
            </div>
            <div className="col-lg-4 editor-right-area">
              <EditorOptions
                inEditProject={inEditProject}
                selectedImgIndex={selectedImgIndex}
                selectedBackground={selectedBackground}
                onBackgroundChange={onBackgroundChange}
                updateInEditProject={setInEditProject}
                setLoading={setIsLoading}
                disabled={selectedImages.length <= 0 && !inEditProject}
              />

              <div className="button-box">
                <button
                  className="main-btn"
                  onClick={downloadSelectedImage}
                  disabled={!inEditProject}
                >
                  {/* DOWNLOAD SELECTED */}
                  Download
                </button>
                {/* <button
                  className="main-btn-filled"
                  onClick={downloadAllImages}
                  disabled={!inEditProject}
                >
                  Download All Images
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CarImageEditor;

const aspectRatios = {
  '16:9': 16 / 9,
  '8:5': 8 / 5,
  '3:2': 3 / 2,
  '4:3': 4 / 3,
};
