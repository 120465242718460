import { CarProject, EditorImage, EditorProject } from '../../../Interfaces';
import { getImagesBase64 } from '../../../Services';
import { BackgroundImage } from '../../../Types';
import {
  backgroundImages,
  defaultEditorImageFineTunes,
  editorImageFilters,
} from './Constants';

export const fetchImagesBase64 = async (imagesUrls: string[]) => {
  try {
    const { data } = await getImagesBase64([...imagesUrls]);
    return data.base64Images;
  } catch (err) {
    return [];
  }
};

export const mapCarProjectToEditorProject = async (project: CarProject) => {
  const { _id, carStudioId, userId, createdAt, updatedAt, images } = project;

  const rawImages = images.map((x) => x.rawImageUrl);
  const rawImagesBase64 =
    rawImages.length > 0 ? await fetchImagesBase64(rawImages) : [];

  const defaultEditorFilter = editorImageFilters.find(
    (x) => x.name === 'Default'
  );

  const editorImages = images.map<EditorImage>((x, i) => ({
    _id: x._id,
    carImageUrl: x.rawImageUrl,
    carImageUrlBase64: rawImagesBase64[i],
    processedImageUrl: x.imageUrl,
    size: { width: 200, height: 200 },
    position: { x: 150, y: 150 },
    rotationAngle: 0,
    pinturaEditedImageBase64: undefined,
    fineTunes: { ...defaultEditorImageFineTunes },
    flipX: false,
    flipY: false,
    appliedFilter: defaultEditorFilter!,
    aspectRatio: '16:9',
  }));

  const appliedBackground =
    images.length > 0
      ? backgroundImages.find((x) => x.url === images[0].platformImageUrl)
      : null;
  const defaultBackgroundImg: BackgroundImage = backgroundImages[0];

  const mappedProject: EditorProject = {
    _id,
    carStudioId,
    userId,
    createdAt,
    updatedAt,
    images: [...editorImages],
    background: appliedBackground ?? defaultBackgroundImg,
  };

  return mappedProject;
};
