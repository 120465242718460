import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
import { PageMetadata } from '../Types/meta';
import { getPageMetadata } from '../Services/MetaData.service';
import 'react-quill/dist/quill.snow.css';
// Define the shape of the context
type SeoContextActions = {
  metaData: PageMetadata | null;
  loadMetadata: (url: string) => void;
  removeMetadata: () => void;
};

// Create the context
const SeoMetaDataContext = createContext({} as SeoContextActions);

// Create the provider component
const SeoMetaDataProvider: FC<PropsWithChildren> = ({ children }) => {
  const [metaData, setMetaData] = useState<PageMetadata | null>(null);

  // Function to fetch and update the username
  const loadMetadata = async (url: string) => {
    try {
      const response = await getPageMetadata(url);
      setMetaData(response!);
    } catch (error) {
      setMetaData(null);
    }
  };

  const removeMetadata = () => {
    setMetaData(null);
  };
  return (
    <SeoMetaDataContext.Provider
      value={{ metaData, loadMetadata, removeMetadata }}
    >
      {metaData && (
        <Helmet>
          <title>{metaData.page_name}</title>
          <div dangerouslySetInnerHTML={{ __html: metaData.content }} />
        </Helmet>
      )}
      {children}
    </SeoMetaDataContext.Provider>
  );
};

// Custom hook to use the context
const useSeoMetaData = (): SeoContextActions => {
  const context = useContext(SeoMetaDataContext);

  if (!context) {
    throw new Error('useSeoMetaData must be used within a SeoMetaDataProvider');
  }

  return context;
};

export { SeoMetaDataProvider, useSeoMetaData };
