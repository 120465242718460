import { createContext, FC, PropsWithChildren, useContext } from 'react';
import { message } from 'antd';
import { NoticeType } from 'antd/es/message/interface';

type AdminSnackBarContextActions = {
  showSnackBar: (text: string, type: NoticeType) => void;
};

const AdminSnackBarContext = createContext({} as AdminSnackBarContextActions);

const AdminSnackBarProvider: FC<PropsWithChildren> = ({ children }) => {
  const [messageApi, contextHolder] = message.useMessage();

  const showSnackBar = (text: string, type: NoticeType) => {
    messageApi.open({
      type,
      content: text,
    });
  };

  return (
    <AdminSnackBarContext.Provider value={{ showSnackBar }}>
      {children}
      {contextHolder}
    </AdminSnackBarContext.Provider>
  );
};

const useAdminSnackBar = (): AdminSnackBarContextActions => {
  const context = useContext(AdminSnackBarContext);

  if (!context) {
    throw new Error('useSnackBar must be used within an AdminSnackBarProvider');
  }

  return context;
};

export { AdminSnackBarProvider, useAdminSnackBar };
