import axios from 'axios';

const usersAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});
export const getUserProfile = async () => {
  const token = localStorage.getItem('token');
  return usersAxios.get<any>('/user/profile', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
interface UpdateUserRequest {
  username: string;
  email: string;
  password?: string;
  phoneNumber: string | number;
  profilePicture: string;
}

export const updateUser = async (payload: UpdateUserRequest) => {
  const token = localStorage.getItem('token');

  return usersAxios.put<UpdateUserRequest>('user/update-profile', payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const changePassword = async (
  currentPassword: string,
  newPassword: string
) => {
  const token = localStorage.getItem('token');
  return usersAxios.put<any>(
    'user/change-password',
    {
      currentPassword,
      newPassword,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const submitContactForm = async (formData: {
  name: string;
  email: string;
  company_name: string;
  phone_number: string;
}) => {
  try {
    const token = localStorage.getItem('token');

    const response = await usersAxios.post('/contact/contact-us/', formData, {
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error submitting contact form:', error);
    throw error;
  }
};
