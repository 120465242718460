import axios from 'axios';
import {
  Base64Response,
  CreateCarProjectResponse,
  GetCarProjectResponse,
  ProjectDetails,
  WebEditorImageUploadResponse,
} from '../Interfaces';
import { Pagination } from '../Types';
import qs from 'qs';

// const token = localStorage.getItem('token');
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (err) => {
    if (err.status === 401) {
      localStorage.clear();
      window.location.href = '/login';
    }

    return Promise.reject(err);
  }
);

export const uploadImagesV2 = async (
  imageUrls: string[],
  platformUrl: string
) => {
  const token = localStorage.getItem('token');
  const data: any = {
    platformUrl: platformUrl,
  };

  imageUrls.forEach((img, index) => {
    (data as any)[`images[${index}].fileUrl`] = img;
    (data as any)[`images[${index}].position`] = index === 0 ? 'FRONT' : 'BACK';
  });

  return axios.request<WebEditorImageUploadResponse>({
    method: 'post',
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/cars/add-car`,
    data: qs.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};

export const updateProject = async (
  id: string,
  imageUrls: string[],
  platformUrl: string
) => {
  const token = localStorage.getItem('token');
  const data: any = {
    platformUrl: platformUrl,
  };

  imageUrls.forEach((img, index) => {
    (data as any)[`images[${index}].fileUrl`] = img;
    (data as any)[`images[${index}].position`] = index === 0 ? 'FRONT' : 'BACK';
  });

  return axios.request<WebEditorImageUploadResponse>({
    method: 'put',
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/cars/car-images-update/${id}`,
    data: qs.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};

export const getProjects = async (pagination: Pagination, search?: string) => {
  const token = localStorage.getItem('token');
  const params: any = {
    limit: pagination.limit,
    page: pagination.page,
  };

  if (search && search.trim() !== '') {
    params.search = search;
  }

  return await axiosInstance.get<any>('cars/get-user-car', {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getProject = async (id: string) => {
  const token = localStorage.getItem('token');
  return await axiosInstance.get<ProjectDetails>(`cars/car-image/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getImagesBase64 = async (imageUrls: string[]) => {
  const token = localStorage.getItem('token');
  return await axiosInstance.post<Base64Response>(
    'cars/get-base-64',
    { imageUrls },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const renameCar = async (carStudioId: string, name: string) => {
  const token = localStorage.getItem('token');

  try {
    const response = await axiosInstance.patch<{ message: string }>(
      'cars/rename-car',
      {
        carStudioId: carStudioId,
        name: name,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const createCarProject = async (
  imageUrls: string[],
  platformUrl: string
) => {
  const token = localStorage.getItem('token');
  const data: any = {
    platformUrl: platformUrl,
  };

  imageUrls.forEach((img, index) => {
    (data as any)[`images[${index}].fileUrl`] = img;
    (data as any)[`images[${index}].position`] = index === 0 ? 'FRONT' : 'BACK';
  });

  return axios.request<CreateCarProjectResponse>({
    method: 'post',
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/cars/add-car`,
    data: qs.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};

export const fetchCarProjectDetails = async (id: string) => {
  const token = localStorage.getItem('token');

  return await axiosInstance.get<GetCarProjectResponse>(
    `cars/car-image/${id}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const DownloadedImages = async (imageUrls: string[]) => {
  const token = localStorage.getItem('token');

  return await axiosInstance.post<{ success: boolean }>(
    '/cars/set-downloaded-images',
    { imageUrls },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
