import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
  useEffect,
} from 'react';
import { getUserProfile } from '../Services/Account.service';

// Define the shape of the context
type UserInfoContextActions = {
  user: any;
  refreshUser: () => void;
};

// Create the context
const UserInfoContext = createContext({} as UserInfoContextActions);

// Create the provider component
const UserInfoProvider: FC<PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState<string>('');

  // Function to fetch and update the username
  const fetchUsername = async () => {
    try {
      const response = await getUserProfile();
      setUser(response.data);
    } catch (error) {
      console.error('Error fetching profile:', error);
    }
  };

  // Function to refresh the username
  const refreshUser = () => {
    fetchUsername();
  };

  // Fetch the username on initial load
  useEffect(() => {
    fetchUsername();
  }, []);

  return (
    <UserInfoContext.Provider value={{ user: user, refreshUser }}>
      {children}
    </UserInfoContext.Provider>
  );
};

// Custom hook to use the context
const useUserInfo = (): UserInfoContextActions => {
  const context = useContext(UserInfoContext);

  if (!context) {
    throw new Error('useUserInfo must be used within a UserInfoProvider');
  }

  return context;
};

export { UserInfoProvider, useUserInfo };
