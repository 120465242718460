import { FC, useCallback, useRef, useState } from 'react';
import Images from '../../../../../Images/Images';
import { useAvailableCredits } from '../../../../../Contexts/AvailableCreditsContext';
import CustomModal from '../../../DialogBox/CreditPop-up';

interface Props {
  onSelect: (imageFiles: File[]) => void;
}

const ImagesSelector: FC<Props> = ({ onSelect }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const { availableCredits } = useAvailableCredits();

  // Trigger file input click when container is clicked
  const handleFileClick = useCallback(
    (event: React.MouseEvent) => {
      if (availableCredits === 0) {
        setOpen(true);
        return;
      }
      if (fileInputRef.current && event.target !== fileInputRef.current) {
        fileInputRef.current.click();
      }
    },
    [availableCredits]
  );

  const onFileSelected = useCallback(
    (files: FileList | null) => {
      if (!files || files.length === 0) {
        return;
      }

      const allowedTypes = [
        'image/png',
        'image/jpg',
        'image/jpeg',
        'image/webp',
      ];
      const validFiles: File[] = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (allowedTypes.includes(file.type)) {
          validFiles.push(file);
        }
      }

      if (validFiles.length > 0) {
        onSelect(validFiles);
      }
    },
    [onSelect]
  );

  return (
    <>
      <CustomModal open={open} handleClose={() => setOpen(false)} />
      <div onClick={handleFileClick} style={{ cursor: 'pointer' }}>
        <input
          ref={fileInputRef}
          multiple
          type="file"
          id="fileInput"
          style={{ display: 'none' }}
          accept="image/png, image/jpg, image/jpeg, image/webp"
          onChange={(event) => onFileSelected(event.target.files)}
        />

        <div className="d-flex align-items-center">
          <img
            src={Images.Share}
            alt="upload"
            className="add-image-upload img-fluid"
          />
          Upload an Image
          <button className="main-btn">
            <p>Upload</p>
          </button>
          <button className="main-btn mobile-view">Gallery</button>
        </div>
      </div>
    </>
  );
};

export default ImagesSelector;
